.container {
    /* background-color: rgb(250, 255, 230); */
    width: 100% !important;
    /* border: 1px solid grey; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    margin: auto;
    padding: 10px 10px; 
}

.row_table {
    display: flex;
    margin-bottom: 10px;
    border: 1.5px solid black;
}

.row_table .row_left {
    width: 20%;
    font-weight: bold;
    text-align: center;
    border-right: 1.5px solid black;
    /* border: 1px solid black; */
}

.row_table .row_right {
    width: 90%;
}

/* .main_container .date{
    width: 30%;
    text-align: center;
    font-weight: bold;
} */

.table {
    text-align: center;
    /* border-collapse: collapse; */
    width: 100%;
    margin-bottom: 20px !important;
    /* background-color: rgb(255, 214, 214); */
}

.table th,
.table td {
    border: 1.5px solid black;
    /* padding: 8px; */
    text-align: center;
}

.originator {
    margin: 5px 0;
    font-weight: bold;
    text-transform: uppercase;
}

.tyre_dual_table {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
    /* border: 1.5px solid rgb(233, 213, 213); */
}

.main_row_table {
    /* background-color: red; */
    width: 48%;
}

.dual_table_left {
    display: flex;
    border: 1px solid black;
    /* background-color: rgb(220, 220, 250); */
}

.dual_table_left1 {
    /* border: 1px solid lime; */
    width: 45%;
    height: 100%;
    padding: 0 10px;
    font-weight: bold;
    border-right: 1.5px solid black;
}

.dual_table_left2 {
    /* border: 1px solid lime; */
    height: 100%;
    width: max-content;
    padding: 0 10px;
}

.test_type {
    /* background-color: rgb(183, 164, 164); */
    /* width: 100%; */
    margin: 20px 0;
}

.test_type_row {
    /* background-color: red; */
    display: flex;
    border: 1px solid black;
}

.test_type_left {
    font-weight: bold;
    border-right: 1.5px solid black;
    width: 20%;
    padding: 0 10px;
    /* text-align: center; */
    /* background-color: pink; */
}

.test_type_left1 {
    width: max-content;
    padding: 0 20px;
    /* background-color: aqua; */
}

.test_request_no_and_date {
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    padding: 20px 4px;
    margin: 20px 0;
    border: 1px solid black;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header .logo {
    width: 25%;
}

.header .logo img {
    width: 100%;
}

.main_heading {
    width: 50%;
    text-align: center;
    display: flex;
    justify-self: center;
    /* align-items: start; */
    flex-direction: column;
    font-size: 30px;
    font-weight: bold;
    text-align: center !important;
    /* background-color: red; */
}

.main_heading h3 {
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.strict_confidentially {
    width: 20%;
}

.strict_confidentially p {
    font-weight: bold;
    text-align: center;
    border: 1px solid black;
    padding: 5px 0px;
}

.heading {
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    border: 1px solid black;
}

.download_button {
    border-radius: 10px;
    font-size: 30px;
    color: grey;
    width: 100%;
    display: flex;
    justify-content:end ;
    transition: 200ms;
    margin-bottom: 50px;
}

.download_button:hover {
    font-size: 40px;
    /* transform: scaleY(1.2); */
    color: rgb(53, 137, 228);
}