body {
  margin: 0;
  font-family: "PT Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0.05);
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  max-width: 2000px;
  margin: 0 auto;
  background-color: white;
  /* overflow-x: scroll; */
  height: 88vh;
  margin-bottom: 20px;

}

.container_test_request {
  height: max-content;
}


@media screen and (max-width: 4000px) {
  .container {
    margin-top: 2.5%;
    width: 95%;
  }
}
@media screen and (max-width: 700px) {
  .container {
    width: 100%;
  }
}

.table {
  width : 100%;
  /* margin-top: 20px; */
  height: 60vh !important;
  display: block;
  overflow: auto;
  margin-top: 24px;
}

.input {
  border: none;
  outline: none;
}

textarea:focus, input:focus {
  border: 1px solid rgba(0, 0, 0, 0.4);
  outline: 1px solid rgba(0, 0, 0, 0.2);
}

.input:focus {
  border: none;
  outline: none;
}

input[type=checkbox] {
  accent-color: rgba(18, 48, 100, 1);
  width: "20px" !important;
  height: "20px";
  justify-self: start;
}
input::placeholder {
  color: #979797;
  opacity: 1;
}

textarea::placeholder {
  color: #979797;
  opacity: 1;
}

.text-secondary {
  color: rgba(18, 48, 100, 1);
}
.bg-dark-blue {
  background: rgba(18, 48, 100, 1);
}
.bg-secondary {
  background: rgba(215, 229, 255, 1);
}
.text-error {
  color: rgba(253, 67, 68, 1)
}
.bg-error {
  background: rgba(255, 181, 181, 0.4);
}
.text-success {
  color: rgba(80, 124, 30, 1);
}
.bg-success {
  background: rgba(141, 193, 83, 0.4);
}
.text-warning {
  color: rgba(157, 122, 20, 1);
}
.bg-warning {
  background: rgba(253, 188, 0, 0.4);
}
.text-dark-grey {
  color: rgba(107, 117, 136, 1);
}
.text-grey {
  color: rgba(125, 131, 152, 1)
}
.change-my-color {
  fill: green;
}
.drop-shadow {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.bottom-shadow {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);
}
.inter{
  font-family: 'Inter', sans-serif;
}
.heading {
  font-size: 16px;
  font-weight: 800;
  margin-top: 8px;
  margin-bottom: 8px;
  font-family: 'Inter', sans-serif;
}
progress[value]::-webkit-progress-bar {
  border-radius: 60px;
}

progress[value]::-webkit-progress-value {
  border-radius: 60px;
  background-color: rgba(18, 48, 100, 1);
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 251px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 2;
  top: 120%;
  left: -10%;
  height: "200px";
  overflow-y: auto;
  transition: opacity 0.3s;
  background-color: #ffffff;
  border-radius: 10px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: -5%;
  left: 50%;
  margin-left: -5px;
  margin-bottom: 10px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  transform: rotate(180deg);
  height: 8px;
}

.tooltip .tooltiptext {
  visibility: visible;
  opacity: 1;
  background-color: #ffffff;
  padding: 12px 12px 12px 12px;
  box-shadow: 0 4px 8px 0 rgba(85, 85, 85, 0.2), 0 6px 20px 0 rgba(68, 68, 68, 0.19);

}

.hide {
  display: none;
}
    
.hoverDiv:hover + .hide {
  display: block;
}
/* styles.css */
.otp-input {
  width: 40px;
  height: 50px;
  border-radius: 6px;
  margin-right: 10px;
  border: 1px solid rgb(73, 73, 73); /* Default border color */
  text-align: center;
}

.otp-input:focus {
  border-color: #2e2d31; /* Change border color on focus */
}
